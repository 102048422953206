<template>
  <doorEvent :activeName="`3`" :onlyError="true"></doorEvent>
</template>
<script>
import doorEvent from "./doorEvent";
export default {
  components: { doorEvent },
  data() {
    return {};
  },
  mounted() {},
  props: {},
  watch: {},
  methods: {},
};
</script>
